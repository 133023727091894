<script>
    import MakeSomethingComponent from "../components/make-something.svelte";

    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
</script>

<svelte:head>
    <title>Norelian</title>
    <meta property="og:title" content="Norelian" />
</svelte:head>

<section>
    <div class="section-home pt-100 pb-100">
        <div class="container">
            <h1 class="section-title text-center txt__title__home">
                All your software development needs
                <span style="display:block;">in one place</span>
            </h1>

            <div class="text-center pt-50 pb-50">
                <a
                    href="/contact"
                    class="btn btn-primary btn-lg section-button-contactus"
                >
                    Contact Us
                </a>
            </div>

            <div class="text-center pt-50 pb-50">
                <img src="/Artboard-11@2x.png" width="100%" alt="norelianos" />
            </div>

            <div class="section-seemore pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <img
                            src="/wallet2-home-pic1-1.svg"
                            width="100%"
                            alt="desc"
                        />
                    </div>
                    <div class="col-12 col-md-7">
                        <h6>
                            <span class="badge">
                                <i class="material-icons rigth__code">code</i>
                                Norelian
                            </span>
                        </h6>
                        <h2 class="subtitle">We are Norelian</h2>

                        <p class="we__are__nor">
                            Our services are delivered using a nearshoring model
                            that enables organizations to better manage their
                            technology projects through specialized consultants.
                        </p>

                        <a
                            href="/about"
                            class="btn btn-primary btn-md section-button-contactus"
                        >
                            See more
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="section-home-2 pt-100 pb-100">
        <div class="container">
            <div class="section-seemore pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-7">
                        <h6>
                            <span class="badge">
                                <i class="material-icons rigth__code">code</i>
                                Norelian
                            </span>
                        </h6>
                        <h2 class="subtitle">
                            Norelian
                            <span class="home__sub">Consultancy</span>
                        </h2>

                        <p class="we__are__nor">
                            We help transform businesses through our experience.
                            Share your needs with us and our team of experts
                            will assist you in determining the best course of
                            action to take for your projects.
                        </p>

                        <a
                            href="/consultancy"
                            class="btn btn-primary btn-md section-button-contactus"
                        >
                            See more
                        </a>
                    </div>
                    <div class="col-12 col-md-5">
                        <img src="/Consulting.png" width="100%" alt="desc" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="section-home-2 pt-100 pb-100">
        <div class="container">
            <div class="section-seemore pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <img src="/Developer.png" width="100%" alt="desc" />
                    </div>
                    <div class="col-12 col-md-7">
                        <h6>
                            <span class="badge">
                                <i class="material-icons rigth__code">code</i>
                                Norelian
                            </span>
                        </h6>
                        <h2 class="subtitle">
                            Norelian
                            <span class="home__sub">Development</span>
                        </h2>

                        <p class="we__are__nor">
                            We assist organizations in overcoming their
                            challenges using state of the art technology
                            solutions. Combining our business and industry
                            expertise, we develop solutions to help your
                            business grow.
                        </p>

                        <a
                            href="/development"
                            class="btn btn-primary btn-md section-button-contactus"
                        >
                            See more
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="section-home-5 pt-50 pb-100">
        <div class="container">
            <div class="text-center">
                <h6>
                    <span class="badge">
                        <i class="material-icons rigth__code">code</i>
                        Norelian
                    </span>
                </h6>
                <h2 class="subtitle">Software Project Management</h2>
            </div>

            <div class="row pt-50 pb-50 section-softproman">
                <div class="col-12 col-lg-4">
                    <div class="card card-my1">
                        <div class="card-body">
                            <h5 class="card-title">Scrum Methodology</h5>
                            <p class="card-text">
                                A lightweight framework to be used by a team of
                                developers and designers. This methodology
                                allows generating work prototypes faster so that
                                clients can view the results without having to
                                wait for months for an MVP.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-4">
                    <div class="card card-my2">
                        <div class="card-body">
                            <h5 class="card-title">Talent & Experience</h5>
                            <p class="card-text">
                                We have the specialized skills needed by our
                                clients. Our staff is made up of Software
                                Developers, Testers, and an excellent Creative
                                Team, all of whom are specialized in a large
                                variety of industries.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-4">
                    <div class="card card-my3">
                        <div class="card-body">
                            <h5 class="card-title">Jira & Hubstaff</h5>
                            <p class="card-text">
                                We use Jira, the best project management tool
                                used by agile teams, as well as Hubstaff, an
                                employee monitoring tool, which tracks work,
                                employee performance and work in progress.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center">
                <h2 class="subtitle">
                    Some stats that make us <span>proud</span>
                </h2>
            </div>

            <div class="row pt-50 pb-50 section-prod">
                <div class="col-12 col-lg-3">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title">20+</h2>
                            <p class="card-text">Customer apps maintained</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-3">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title">3k+</h2>
                            <p class="card-text">Development hours a month</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-3">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title">10k+</h2>
                            <p class="card-text">App visits per month</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-3">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title">500k+</h2>
                            <p class="card-text">Lines of code a year</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<MakeSomethingComponent
    text={"Let’s make something awesome together."}
    textButton={"Talk to a Human."}
/>
