<script>
  import { locale, _ } from "svelte-i18n";
  import { activeRoute } from "../pager/Router.svelte";

  export let stickyHeader;

  const goTo = (idSection) =>
    window.scrollTo({
      top: document.getElementById(idSection).offsetTop,
      behavior: "smooth",
    });

  const changeLanguage = (lang) => {
    locale.set(lang);
    localStorage.setItem("lang", lang);
  };
  let currentLocation = "/";

  activeRoute.subscribe((active) => (currentLocation = active.path));
</script>

<header>
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-dark bg-dark"
    id="header-sticky"
  >
    <div class="container">
      <a class="navbar-brand" href="/">
        <img src="/logo.png" alt="Logo Norelian" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>

      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <ul
          class="navbar-nav ms-auto my-2 my-lg-0"
          style="--bs-scroll-height: 100px;"
        >
          <li class="nav-item">
            <a
              class="nav-link {currentLocation == '/' || currentLocation == '**'
                ? 'active'
                : ''}"
              aria-current="page"
              href="/"
            >
              Home
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link {currentLocation == '/about' ? 'active' : ''}"
              aria-current="page"
              href="/about"
            >
              About
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link {currentLocation == '/consultancy'
                ? 'active'
                : ''}"
              aria-current="page"
              href="/consultancy"
            >
              Consultancy
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link {currentLocation == '/development'
                ? 'active'
                : ''}"
              aria-current="page"
              href="/development"
            >
              Development
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link {currentLocation == '/contact' ? 'active' : ''}"
              aria-current="page"
              href="/contact"
            >
              Contact
            </a>
          </li>

          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#!"
              id="navbarScrollingDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {#if $locale == "es"}
                {$_("Spanish")}
              {:else}
                {$_("English")}
              {/if}
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
              <li>
                <a
                  class="dropdown-item"
                  href="#!"
                  on:click|preventDefault={() => changeLanguage("es")}
                >
                  {$_("Spanish")}
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="#!"
                  on:click|preventDefault={() => changeLanguage("en")}
                >
                  {$_("English")}
                </a>
              </li>
            </ul>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</header>
