<script>
    import MakeSomethingComponent from "../components/make-something.svelte";

    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
</script>

<svelte:head>
    <title>Development | Norelian</title>
    <meta property="og:title" content="Development | Norelian" />
</svelte:head>

<section>
    <div class="section-consultancy pt-50 pb-100">
        <div class="container">
            <div class="text-center">
                <h6>
                    <span class="badge">
                        <i class="material-icons rigth__code">code</i>
                        Norelian
                    </span>
                </h6>
                <h1 class="subtitle txt__develop">
                    Norelian
                    <span> Development </span>
                </h1>
            </div>

            <div class="section-description text-center develop__sub__cont">
                <p class="sub__develop">
                    We assist organizations in overcoming their challenges using
                    state of the art technology solutions. With a clear approach
                    to the different types of industries, we combine our
                    business and industry expertise to develop solutions that
                    will help your business grow.
                </p>
            </div>

            <div class="section-seemore pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-7">
                        <h6>
                            <span class="badge">
                                <i class="material-icons rigth__code">code</i>
                                Norelian
                            </span>
                        </h6>
                        <h1 class="subtitle web__develop">Web Applications</h1>
                        <h4 class="subtitle sub__web__dev">
                            Nowadays, web-based applications help organizations
                            fulfill their functional and commercial scopes.
                        </h4>

                        <p class="f-18">
                            The web not only offers unlimited information, but
                            also helps organizations open up to new business
                            opportunities. Whether apps, portals, or other
                            digital solutions, at Norelian we give your ideas
                            the logic they need to take your business to the
                            next level.
                        </p>

                        <p class="f-18">
                            Tailored web applications provide the
                            entrepreneurial mindset and information required to
                            help organizations transform their current
                            challenges into optimized, risk-reducing, and
                            ultimately profitable solutions.
                        </p>
                    </div>
                    <div class="col-12 col-md-5">
                        <img
                            src="/wallet2-security-pic3-1.svg"
                            width="100%"
                            alt="desc"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="section-home-2 pt-100 pb-100">
        <div class="container">
            <div class="section-seemore pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <img
                            src="/wallet2-security-pic4-1.svg"
                            width="100%"
                            alt="desc"
                        />
                    </div>
                    <div class="col-12 col-md-7">
                        <h6>
                            <span class="badge">
                                <i class="material-icons rigth__code">code</i>
                                Norelian
                            </span>
                        </h6>
                        <h2 class="subtitle web__develop">
                            Mobile Applications
                        </h2>
                        <h4 class="subtitle sub__web__dev">
                            As a means to an end, mobile applications allow a
                            great idea to become a reality.
                        </h4>

                        <p class="f-18">
                            Mobile apps are absolutely essential for the growth
                            of your business today, without a doubt.
                        </p>

                        <p class="f-18">
                            Our team of UX / UI designers and experienced
                            programmers make sure that your applications have
                            all of the elements they need to become functional,
                            easy to use, and attractive.
                        </p>
                        <p class="f-18">
                            Our ample experience has enabled us to transform our
                            clients’ problems into technological solutions by
                            designing their websites and mobile applications.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="section-consultancy pt-50 pb-100">
        <div class="container">
            <div class="section-seemore pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-7">
                        <h6>
                            <span class="badge">
                                <i class="material-icons rigth__code">code</i>
                                Norelian
                            </span>
                        </h6>
                        <h1 class="subtitle web__develop">Web 2.0</h1>
                        <h4 class="subtitle sub__web__dev">
                            A Web 2.0 website allows users to improve their
                            interaction and experience.
                        </h4>

                        <p class="f-18">
                            Your website is the face of your company and, in
                            some cases, it may be the first point of contact for
                            potential clients. A strategic website provides
                            credibility, 24/7 online access, thus contributing
                            to the achievement of your company’s online goals.
                        </p>

                        <p class="f-18">
                            The Web 2.0 technology is based on the users’
                            experience and a Search Engine Optimization (SEO)
                            for creating websites. It is possible to identify
                            and predict the behavior of consumers and
                            organizations by using these parameters.
                        </p>

                        <p class="f-18">
                            Let us help you improve your users’ experience by
                            simply updating your website!.
                        </p>
                    </div>
                    <div class="col-12 col-md-5">
                        <img
                            src="/wallet2-pricing-pic1-1.svg"
                            width="100%"
                            alt="desc"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="section-home-2 pt-100 pb-100">
        <div class="container">
            <div class="section-seemore pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <img
                            src="/wallet2-security-pic1-1.svg"
                            width="100%"
                            alt="desc"
                        />
                    </div>
                    <div class="col-12 col-md-7">
                        <h6>
                            <span class="badge">
                                <i class="material-icons rigth__code">code</i>
                                Norelian
                            </span>
                        </h6>
                        <h2 class="subtitle web__develop">
                            Line of Business Applications (LOB)
                        </h2>
                        <h4 class="subtitle sub__web__dev">
                            Take your LOB application development to the highest
                            level!.
                        </h4>

                        <p class="f-18">
                            If your organization needs expert custom developers
                            and programmers, Norelian has the best talent for
                            the maintenance and improvement of your current
                            systems.
                        </p>

                        <p class="f-18">
                            As a technology partner, we help organizations turn
                            their ideas into reality. We make sure that our wide
                            range of development services adapt to the specific
                            needs of our clients’ business core.
                        </p>
                        <p class="f-18">
                            We create digital solutions that lead to the
                            improvement and optimization of businesses’ daily
                            operations.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="section-consultancy pt-50 pb-100">
        <div class="container">
            <div class="section-seemore pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-7">
                        <h6>
                            <span class="badge">
                                <i class="material-icons rigth__code">code</i>
                                Norelian
                            </span>
                        </h6>
                        <h1 class="subtitle web__develop">UX/UI</h1>
                        <h4 class="subtitle sub__web__dev">
                            We believe that digital solutions can provide
                            meaningful experiences when the organizations meet
                            the needs of their users and achieve their own
                            business goals.
                        </h4>

                        <p class="f-18">
                            Norelian’s approach focuses on the user to build
                            useful experiences and amazing interfaces. We base
                            our work on the industry’s best practices in terms
                            of accessibility, extensibility, and performance.
                        </p>

                        <p class="f-18">
                            Strategic user experiences help generate client
                            satisfaction and loyalty, which is reflected in
                            higher yields.
                        </p>

                        <p class="f-18">
                            Our pledge is to stay committed to a recurring
                            process that takes your solutions to the next level,
                            interacts with your audience, and is capable of
                            withstanding the passage of time.
                        </p>
                    </div>
                    <div class="col-12 col-md-5">
                        <img
                            src="/wallet2-faq-pic1-1.svg"
                            width="100%"
                            alt="desc"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<MakeSomethingComponent
    text={"Let’s make something awesome together."}
    textButton={"Talk to a Human."}
/>
