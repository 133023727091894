<script>
    import { _ } from "svelte-i18n";
    import { onMount, onDestroy } from "svelte";
    import {
        useForm,
        required,
        validators,
        email,
        maxLength,
        minLength,
        Hint,
        HintGroup,
    } from "svelte-use-form";
    import { environments } from "../assets/environments";

    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });

    const dataForm = useForm();
    let disabledButton = false;
    let recaptchaToken = null;
    let sendingEmail = false;
    let errorSending = false;
    let formContact = {
        name: "",
        phone: "",
        email: "",
        accept: false,
    };

    const recaptchaCheck = (event) => {
        recaptchaToken = event;
    };

    const recaptchaError = () => {
        recaptchaToken = null;
    };

    onMount(() => {
        window.recaptchaCheck = recaptchaCheck;
        window.recaptchaError = recaptchaError;
    });

    onDestroy(() => {
        window.recaptchaCheck = null;
        window.recaptchaError = null;
    });

    const handlerSubmitForm = (e) => {
        sendingEmail = false;
        errorSending = false;
        if (recaptchaToken) {
            disabledButton = true;

            fetch(`${environments.api}/contactus`, {
                method: "POST",
                body: JSON.stringify(
                    Object.assign({}, formContact, {
                        "g-recaptcha-response": "doaqoaddola",
                    }),
                ),
                headers: {
                    "content-type": "application/json",
                },
            })
                .then((response) =>
                    response.ok ? true : Promise.reject(response),
                )
                .then((response) => {
                    formContact = {
                        name: "",
                        phone: "",
                        email: "",
                    };
                    disabledButton = false;
                    sendingEmail = true;
                    recaptchaToken = null;
                })
                .catch((err) => {
                    console.error(err);
                    disabledButton = false;
                    errorSending = true;
                    recaptchaToken = null;
                });
        } else {
            alert("Recatpcha invalid");
        }
    };
</script>

<svelte:head>
    <title>Contact - Norelian</title>
    <meta property="og:title" content="Contact - Norelian" />
    <script src="https://www.google.com/recaptcha/api.js" async defer></script>
</svelte:head>

<section>
    <div class="section-contact pt-100 pb-100">
        <div class="container">
            <div class="text-center">
                <h6>
                    <span class="badge">
                        <i class="material-icons">code</i>
                        Norelian
                    </span>
                </h6>
                <h1 class="section-title text-center txt__about__title">
                    If you have a question,
                </h1>
                <h1 class="section-title text-center" style="font-size: 47px;">
                    we are here to help you.
                </h1>

                <h1 class="section-title text-center diss__contact">
                    <span>Contact us</span>
                </h1>
            </div>

            <div class="section-description text-center">
                <p style="font-weight: 300;">
                    You're not going to hit a ridiculously long phone menu when
                    you call us. Your email isn't going to the inbox abyss,
                    never to be seen or heard from again. At Norelian, we
                    provide the exceptional service we'd want to experience
                    ourselves!
                </p>
            </div>

            <div class="section-contactusdetail pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-3 address__cont">
                        <h6 class="subtitle">Address</h6>

                        <p style="font-weight: 300; font-size: 18px;">
                            Norelian LLC
                            <br />
                            4299 NW 36th St
                            <br />
                            Ste 1
                            <br />
                            Miami Springs
                            <br />
                            FL 33496, US
                        </p>
                    </div>
                    <div class="col-12 col-md-9">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <h6 class="subtitle">Phone</h6>

                                <a
                                    class="tlf__edit"
                                    href="tel:+1 (828) 483 78 42"
                                    >+1 (828) 483 78 42</a
                                >
                            </div>
                            <div class="col-12 col-md-6">
                                <h6 class="subtitle">Sales</h6>

                                <a
                                    class="tlf__edit"
                                    href="mailto:sales@norelian.com"
                                    >sales@norelian.com</a
                                >
                            </div>
                            <div
                                class="col-12 col-md-6"
                                style="margin-top: 40px;"
                            >
                                <h6 class="subtitle">Information</h6>

                                <a
                                    class="tlf__edit"
                                    href="mailto:info@norelian.com"
                                    >info@norelian.com</a
                                >
                            </div>
                            <div
                                class="col-12 col-md-6"
                                style="margin-top: 40px;"
                            >
                                <h6 class="subtitle">Phone</h6>

                                <a
                                    class="tlf__edit"
                                    href="mailto:support@norelian.com"
                                    >support@norelian.com</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-seemore pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-5 back__code__nor">
                        <h6>
                            <span class="badge">
                                <i class="material-icons">code</i>
                                Norelian
                            </span>
                        </h6>

                        <p>
                            Send us your contact information using the form and
                            we will be in touch shortly.
                        </p>
                    </div>
                    <div class="my-contact col-12 col-md-7">
                        <form
                            use:dataForm
                            on:submit|preventDefault={handlerSubmitForm}
                        >
                            <!--
                            {#if $dataForm.name?.touched && $dataForm.name?.errors.required}
                            {#if $dataForm.name.errors.required}
                                Debes selecciónar un país.
                            {/if}
                            {/if}
                            <br /-->
                            <div class="card">
                                <div class="card-body">
                                    <h2
                                        class="card-title"
                                        style="padding: 34px 50px 0px 50px;"
                                    >
                                        Have a question?
                                        <span
                                            style="display:block; color: #000000;"
                                            >Drop us a line!</span
                                        >
                                    </h2>

                                    <div
                                        class="my-form-contact"
                                        style="padding: 20px 70px 34px 70px;"
                                    >
                                        <div class="mb-3">
                                            <input
                                                use:validators={[
                                                    required,
                                                    minLength(1),
                                                    maxLength(30),
                                                ]}
                                                type="text"
                                                class="form-control"
                                                id="fullname"
                                                name="fullname"
                                                bind:value={formContact.name}
                                                autocomplete="off"
                                                autofocus
                                                required
                                                minlength="1"
                                                maxlength="30"
                                                placeholder="Full Name *"
                                            />
                                            <HintGroup
                                                for="fullname"
                                                class="help-block help-block-left field-invalid"
                                            >
                                                <Hint on="required">
                                                    {$_(
                                                        "This field is required",
                                                    )}
                                                </Hint>
                                                <Hint
                                                    on="minLength"
                                                    hideWhenRequired
                                                    let:value
                                                >
                                                    Minimo de caracteres: {value}
                                                </Hint>
                                                <Hint
                                                    on="maxLength"
                                                    hideWhen="minLength"
                                                    let:value
                                                >
                                                    Máximo de caracteres: {value}
                                                </Hint>
                                            </HintGroup>
                                        </div>
                                        <div class="mb-3">
                                            <input
                                                use:validators={[
                                                    required,
                                                    minLength(1),
                                                    maxLength(30),
                                                ]}
                                                type="tel"
                                                class="form-control"
                                                id="phone"
                                                name="phone"
                                                bind:value={formContact.phone}
                                                autocomplete="off"
                                                required
                                                minlength="0"
                                                maxlength="20"
                                                placeholder="Phone *"
                                            />
                                            <HintGroup
                                                for="phone"
                                                class="help-block help-block-left field-invalid"
                                            >
                                                <Hint on="required">
                                                    {$_(
                                                        "This field is required",
                                                    )}
                                                </Hint>
                                                <Hint
                                                    on="minLength"
                                                    hideWhenRequired
                                                    let:value
                                                >
                                                    Minimo de caracteres: {value}
                                                </Hint>
                                                <Hint
                                                    on="maxLength"
                                                    hideWhen="minLength"
                                                    let:value
                                                >
                                                    Máximo de caracteres: {value}
                                                </Hint>
                                            </HintGroup>
                                        </div>
                                        <div class="mb-3">
                                            <input
                                                use:validators={[
                                                    required,
                                                    email,
                                                ]}
                                                type="email"
                                                class="form-control"
                                                id="email"
                                                name="email"
                                                bind:value={formContact.email}
                                                autocomplete="off"
                                                required
                                                minlength="0"
                                                placeholder="Email address *"
                                            />
                                            <HintGroup
                                                for="email"
                                                class="help-block help-block-left field-invalid"
                                            >
                                                <Hint on="required">
                                                    {$_(
                                                        "This field is required",
                                                    )}
                                                </Hint>
                                                <Hint
                                                    on="email"
                                                    hideWhenRequired
                                                >
                                                    {$_(
                                                        "This must be a valid email",
                                                    )}
                                                </Hint>
                                            </HintGroup>
                                        </div>
                                        <div class="mb-3 form-check">
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                id="accept"
                                                name="accept"
                                                value="yes"
                                                checked={formContact.accept}
                                                on:change={(e) =>
                                                    (formContact.accept =
                                                        e.target.checked)}
                                            />
                                            <label
                                                class="form-check-label"
                                                for="accept"
                                                style="font-weight: 300;"
                                                >I consent to the processing of
                                                data for the purpose of
                                                telephone and/or email contact.
                                                *</label
                                            >
                                        </div>
                                        <div class="mb-3 recapchat">
                                            <div
                                                class="g-recaptcha"
                                                data-sitekey={environments.googleRecaptchaSiteKey}
                                                data-callback="recaptchaCheck"
                                                data-error-callback="recaptchaError"
                                                data-expired-callback="recaptchaError"
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            class="btn btn-primary section-button-contactus contact__me"
                                            disabled={!$dataForm.valid ||
                                                disabledButton ||
                                                !recaptchaToken}
                                            >Contact me</button
                                        >

                                        {#if sendingEmail}
                                            <div class="col-12">
                                                {$_("Contact send!")}.
                                            </div>
                                        {/if}
                                        {#if errorSending}
                                            <div class="col-12">
                                                {$_("Warning")}
                                            </div>
                                        {/if}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
