<script>
  import { Router, Route } from "./pager";

  import HomePage from "./home/page.svelte";
  import ContactPage from "./contact/page.svelte";
  import AboutPage from "./about/page.svelte";
  import ConsultancyPage from "./consultancy/page.svelte";
  import DevelopmentPage from "./development/page.svelte";

  export let params;
  export let stickyHeader;

  /*const guard = (ctx, next) => {
    // check for example if user is authenticated
    if (true) {
      redirect("/login");
    } else {
      // go to the next callback in the chain
      next();
    }
  };*/

  let routes = [
    { path: "/", component: HomePage },
    { path: "/contact", component: ContactPage },
    { path: "/about", component: AboutPage },
    { path: "/consultancy", component: ConsultancyPage },
    { path: "/development", component: DevelopmentPage },
    { path: "**", component: HomePage },
  ];
</script>

<Router>
  {#each routes as route}
    <Route
      path={route.path}
      component={route.component}
      bind:stickyHeader
      {params}
    />
  {/each}
</Router>
