<script>
    import MakeSomethingComponent from "../components/make-something.svelte";

    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    document.title = "Consultancy | Norelian";
</script>
<svelte:head>
    <title>Consultancy | Norelian</title>
    <meta property="og:title" content="Consultancy | Norelian" />
</svelte:head>

<section>
    <div class="section-consultancy pt-50 pb-100">
        <div class="container">
            <div class="text-center">
                <h6>
                    <span class="badge">
                        <i class="material-icons rigth__code">code</i>
                        Norelian
                    </span>
                </h6>
                <h1 class="subtitle txt__develop">
                    Norelian
                    <span> Consultancy </span>
                </h1>
            </div>

            <div class="section-description text-center develop__sub__cont">
                <p class="sub__develop">
                    We help transform businesses through our experience. Share
                    your needs with us and our team of experts will assist you
                    in determining the best course of action to take for your
                    projects.
                </p>
            </div>

            <div class="section-seemore pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-7">
                        <h6>
                            <span class="badge">
                                <i class="material-icons">code</i>
                                Norelian
                            </span>
                        </h6>
                        <h1 class="subtitle web__develop">Staff Augmentation</h1>
                        <h4 class="subtitle sub__web__dev">
                            We can provide support to your projects at any stage
                            or phase of their lifecycle through our staff
                            augmentation services.
                        </h4>

                        <p class="f-18">
                            Staff augmentation is an outsourcing strategy
                            commonly used to recruit the right talent for
                            specific projects.
                        </p>

                        <p class="f-18">
                            The flexibility of our terms and conditions for this
                            strategy enable you to add resources at any stage of
                            your project. We assist organizations in reducing
                            staff-related costs without compromising the quality
                            of the deliverables.
                        </p>
                        <p class="f-18">
                            Organizations pay for talent additions ONLY when
                            these are needed!
                        </p>
                    </div>
                    <div class="col-12 col-md-5">
                        <img src="/StaffAug.png" width="100%" alt="desc" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="section-home-2 pt-100 pb-100">
        <div class="container">
            <div class="section-seemore pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <img src="/Consultancy.png" width="100%" alt="desc" />
                    </div>
                    <div class="col-12 col-md-7">
                        <h6>
                            <span class="badge">
                                <i class="material-icons">code</i>
                                Norelian
                            </span>
                        </h6>
                        <h2 class="subtitle web__develop">Technology Consultancy Service</h2>
                        <h4 class="subtitle sub__web__dev">
                            Our team of tech consultants helps organizations
                            achieve their technological objectives by providing
                            appropriate consulting services in our areas of
                            expertise.
                        </h4>

                        <p class="f-18">
                            We provide professional consulting in the following
                            areas:
                        </p>

                        <ul class="f-18">
                            <li>On-Prem, Hybrid and Cloud strategies</li>
                            <li>IT Cost Effectiveness and Reduction</li>
                            <li>IT Procurement and Purchases</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<MakeSomethingComponent
    text={"Start now and make your finances clear and organized"}
    textButton={"Contact Us"}
/>
