<script>
  import { _ } from "svelte-i18n";
  import { activeRoute, redirect } from "./pager";
  import Router from "./Router.svelte";
  import {
    addMessages,
    init,
    getLocaleFromNavigator,
    locale,
  } from "svelte-i18n";

  import en from "./assets/en.json";
  import es from "./assets/es.json";
  import { GoogleAnalytics } from "@beyonk/svelte-google-analytics";

  addMessages("en", en);
  addMessages("es", es);

  init({
    fallbackLocale: "en",
    initialLocale: getLocaleFromNavigator(),
  });

  if (localStorage.getItem("lang")) {
    locale.set(localStorage.getItem("lang"));
  } else {
    locale.set(getLocaleFromNavigator().split("-")[0]);
    localStorage.setItem("lang", getLocaleFromNavigator().split("-")[0]);
  }

  import HeaderComponent from "./header/component.svelte";
  import FooterComponent from "./footer/component.svelte";

  let stickyHeader = false;
  let params = {};
  activeRoute.subscribe((active) => {
    if (active.path == "**") {
      redirect("/");
      return;
    }
    params = Object.assign({}, active.params);
    stickyHeader = false;
    /*window.scrollTo({
      top: 0,
      behavior: "smooth",
    });*/
  });

  let acceptCookies = document.cookie.replace(
    /(?:(?:^|.*;\s*)iAcceptCookies\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  )
    ? document.cookie.replace(
        /(?:(?:^|.*;\s*)iAcceptCookies\s*\=\s*([^;]*).*$)|^.*$/,
        "$1"
      )
    : null;

  const acceptCookiesConfirm = () => {
    acceptCookies = "yes";
    document.cookie = "iAcceptCookies=yes;";
  };
  const rejectCookiesConfirm = () => {
    acceptCookies = "no";
    const cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    document.cookie = "iAcceptCookies=no;";
  };

  const resetConfigCookies = () => {
    acceptCookies = null;
    const cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };
  if (acceptCookies && acceptCookies == "no") {
    rejectCookiesConfirm();
  }
</script>

{#if window.location.hostname.includes("norelian.com") && !window.location.hostname.includes("qa.norelian.com")}
  <GoogleAnalytics properties={["4307660723"]} enabled={true} />
{/if}

<HeaderComponent bind:stickyHeader />

<main>
  <Router {params} bind:stickyHeader />
</main>

<FooterComponent />

<!--{#if !acceptCookies}
  <div class="modal myModalCookies">
    <div class="modal-dialog">
      <div style="border-radius: 0.9rem;" class="modal-content">
        <div
          style="    padding-left: 24px;
        padding-top: 24px;
        padding-bottom: 0px;
        padding-right: 24px; font-weight: bold; border-bottom: none  "
          class="modal-header"
        >
          <h5 style="font-weight: bold;" class="modal-title">
            {$_("We value your privacy")}
          </h5>
        </div>
        <div
          style="padding-left: 24px; padding-right: 24px; width: 99%;  "
          class="modal-body"
        >
          <p>
            {$_(
              'We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking Accept All, you consent to our use of cookies.'
            )}
          </p>
        </div>
        <div
          style=" padding-left: 24px;
        padding-top: 0px;
        padding-bottom: 24px;
        padding-right: 24px; border-top: none "
          class="modal-footer"
        >
          <button
            style="padding: 10px 20px;  background: transparent;
          color: grey;
      }"
            type="button"
            class="btn btn-link"
            on:click={rejectCookiesConfirm}
          >
            {$_("Reject All")}
          </button>
          <button
            style="padding: 10px 30px; "
            type="button"
            class="btn btn-primary"
            on:click={acceptCookiesConfirm}
          >
            {$_("Accept All")}
          </button>
        </div>
      </div>
    </div>
  </div>
{:else if acceptCookies && acceptCookies == "no"}
  <div style=" position: fixed; left: 20px; bottom: 30px;">
    <button
      style="background-color: transparent; border: none"
      aria-label="Cookie Settings"
      on:click={resetConfigCookies}
    >
      <span
        id="scrollUp"
        style="line-height: 58px;
    font-size: xx-large;
    color: rgb(47, 64, 80);
    background: #f8f9fa;
    border: #c3c3c3 1px solid;"
        class="material-icons ls-is-cached lazyloaded"
      >
        cookie
      </span>
      <!-- <img
        alt="Revisit consent button"
        data-src="/img/revisit.svg"
        class=" ls-is-cached lazyloaded"
        src="/img/revisit.svg"
      /> --/>
    </button>
  </div>
{/if}-->
