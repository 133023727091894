<script>
    import MakeSomethingComponent from "../components/make-something.svelte";

    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
</script>

<svelte:head>
    <title>About | Norelian</title>
    <meta property="og:title" content="About | Norelian" />
</svelte:head>

<section>
    <div class="section-about pt-50 pb-100">
        <div class="container">
            <div class="text-center">
                <h6>
                    <span class="badge">
                        <i class="material-icons">code</i>
                        Norelian
                    </span>
                </h6>
                <h1 class="subtitle txt__about__title">
                    Helping our clients reach
                </h1>
                <h1 class="txt__about__title">
                    their goals <span> is our goal </span>
                </h1>
            </div>

            <div class="section-description text-center media__section">
                <p>
                    <strong>
                        Our services are delivered using a nearshoring model
                        that enables organizations to better manage their
                        technology projects through specialized consultants.
                    </strong>
                </p>
                <p class="f-18">
                    Our team of experts has helped a variety of companies build
                    exceptional digital experiences with the goal of helping
                    them achieve short and long-term business goals.
                </p>
                <p class="f-18">
                    We design, develop, and implement digital solutions to
                    increase our clients’ productivity and efficiency.
                </p>
                <p class="f-18">
                    We become our clients’ technological partners, taking up
                    their objectives as our own!
                </p>
            </div>

            <div class="text-center pb-50 pt-50">
                <img
                    src="/BeforeFooter2.png"
                    width="100%"
                    alt="logoguesthub refortoofer"
                />
            </div>

            <div class="text-center">
                <h2 class="subtitle">
                    Some stats that make us <span>proud</span>
                </h2>
            </div>

            <div class="row pt-50 pb-50 section-prod">
                <div class="col-12 col-lg-3">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title">20+</h2>
                            <p class="card-text">Customer apps maintained</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-3">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title">3k+</h2>
                            <p class="card-text">Development hours a month</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-3">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title">10k+</h2>
                            <p class="card-text">App visits per month</p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-3">
                    <div class="card">
                        <div class="card-body">
                            <h2 class="card-title">500k+</h2>
                            <p class="card-text">Lines of code a year</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<MakeSomethingComponent
    text={"Let’s make something awesome together."}
    textButton={"Talk to a Human."}
/>
