<script>
    import { locale, _ } from "svelte-i18n";
    import { activeRoute } from "../pager/Router.svelte";

    let currentLocation = "/";

    activeRoute.subscribe((active) => (currentLocation = active.path));
</script>

<footer>
    <div class="section-footer pt-50 pb-50">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-3">
                    <img src="/logo-03.png" width="100%" alt="desc" />
                </div>
                <div class="col-12 col-md-3" />
                <div class="col-12 col-md-3">
                    <h6 class="subtitle">Navigation</h6>
                    <div class="list-group">
                        <a
                            href="/"
                            class="list-group-item list-group-item-action foot__edit"
                            aria-current="true"
                        >
                            Home
                        </a>
                        <a
                            href="/about"
                            class="list-group-item list-group-item-action foot__edit"
                        >
                            About
                        </a>
                        <a
                            href="/consultancy"
                            class="list-group-item list-group-item-action foot__edit"
                        >
                            Consultancy
                        </a>
                        <a
                            href="/development"
                            class="list-group-item list-group-item-action foot__edit"
                        >
                            Development
                        </a>
                        <a
                            href="/contact"
                            class="list-group-item list-group-item-action foot__edit"
                        >
                            Contact
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <h6 class="subtitle">Address</h6>

                    <p class="txt__footer">
                        Norelian LLC
                        <br />
                        4299 NW 36th St
                        <br />
                        Ste 1
                        <br />
                        Miami Springs
                        <br />
                        FL 33496, US
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="section-footer pb-20">
        <div class="container">
            <div class="row">
                <div class="col-12 section-copyright">
                    <span>
                        &copy; {new Date().getFullYear()} Norelian | All Rights Reserved
                    </span>
                </div>
            </div>
        </div>
    </div>
</footer>
