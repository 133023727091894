<script>
    import { _ } from "svelte-i18n";
    export let text;
    export let textButton;
</script>

<section>
    <div class="section-home-3 pt-20 pb-20">
        <div class="container">
            <div class="section-seemore pt-50 pb-50">
                <div class="row">
                    <div class="col-12 col-md-3">
                        <img src="/wallet2-home-pic4-1-300x217.png" width="100%" alt="desc" />
                    </div>
                    <div class="col-12 col-md-6">
                        <h2 class="subtitle">
                            {$_(text)}
                        </h2>
                    </div>
                    <div class="col-12 col-md-3 text-center">
                        <a
                            href="/contact"
                            class="btn btn-primary btn-block btn-md"
                        >
                            {$_(textButton)}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
